import { default as adminAOa9i81ClJMeta } from "/opt/build/repo/pages/admin.vue?macro=true";
import { default as docs4hr4mD7LDxMeta } from "/opt/build/repo/pages/docs.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as initialize6gWQWj3dytMeta } from "/opt/build/repo/pages/initialize.vue?macro=true";
import { default as _91id_93edeN6pQQVxMeta } from "/opt/build/repo/pages/pages/[id].vue?macro=true";
import { default as indexwdyh8FzEmrMeta } from "/opt/build/repo/pages/pages/index.vue?macro=true";
import { default as pagesASEqLbXMMLMeta } from "/opt/build/repo/pages/pages.vue?macro=true";
import { default as _91file_45id_93R5ptNzD0BqMeta } from "/opt/build/repo/pages/public/[folder-id]/files/[file-id].vue?macro=true";
import { default as indexMxdv8CiS0vMeta } from "/opt/build/repo/pages/public/[folder-id]/files/index.vue?macro=true";
import { default as index8EYB3b4IpIMeta } from "/opt/build/repo/pages/public/[folder-id]/index.vue?macro=true";
import { default as indexzngo4oBjrwMeta } from "/opt/build/repo/pages/public/index.vue?macro=true";
import { default as publicTcFxkFOErFMeta } from "/opt/build/repo/pages/public.vue?macro=true";
import { default as registeryZbpxRGV6SMeta } from "/opt/build/repo/pages/register.vue?macro=true";
import { default as settings6ZNFu1CSghMeta } from "/opt/build/repo/pages/settings.vue?macro=true";
import { default as _91file_45id_93kQEmaNYllSMeta } from "/opt/build/repo/pages/templates/[folder-id]/files/[file-id].vue?macro=true";
import { default as indexZTi6ZP1YMsMeta } from "/opt/build/repo/pages/templates/[folder-id]/files/index.vue?macro=true";
import { default as indexZuq7mtGKKGMeta } from "/opt/build/repo/pages/templates/[folder-id]/index.vue?macro=true";
import { default as indexyKTohDGbkZMeta } from "/opt/build/repo/pages/templates/index.vue?macro=true";
import { default as templatesXQ4q1AVqc3Meta } from "/opt/build/repo/pages/templates.vue?macro=true";
import { default as unregisterCsdZJBLwBbMeta } from "/opt/build/repo/pages/unregister.vue?macro=true";
export default [
  {
    name: adminAOa9i81ClJMeta?.name ?? "admin",
    path: adminAOa9i81ClJMeta?.path ?? "/admin",
    meta: adminAOa9i81ClJMeta || {},
    alias: adminAOa9i81ClJMeta?.alias || [],
    redirect: adminAOa9i81ClJMeta?.redirect,
    component: () => import("/opt/build/repo/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: docs4hr4mD7LDxMeta?.name ?? "docs",
    path: docs4hr4mD7LDxMeta?.path ?? "/docs",
    meta: docs4hr4mD7LDxMeta || {},
    alias: docs4hr4mD7LDxMeta?.alias || [],
    redirect: docs4hr4mD7LDxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/docs.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: initialize6gWQWj3dytMeta?.name ?? "initialize",
    path: initialize6gWQWj3dytMeta?.path ?? "/initialize",
    meta: initialize6gWQWj3dytMeta || {},
    alias: initialize6gWQWj3dytMeta?.alias || [],
    redirect: initialize6gWQWj3dytMeta?.redirect,
    component: () => import("/opt/build/repo/pages/initialize.vue").then(m => m.default || m)
  },
  {
    name: pagesASEqLbXMMLMeta?.name ?? undefined,
    path: pagesASEqLbXMMLMeta?.path ?? "/pages",
    meta: pagesASEqLbXMMLMeta || {},
    alias: pagesASEqLbXMMLMeta?.alias || [],
    redirect: pagesASEqLbXMMLMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pages.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93edeN6pQQVxMeta?.name ?? "pages-id",
    path: _91id_93edeN6pQQVxMeta?.path ?? ":id()",
    meta: _91id_93edeN6pQQVxMeta || {},
    alias: _91id_93edeN6pQQVxMeta?.alias || [],
    redirect: _91id_93edeN6pQQVxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwdyh8FzEmrMeta?.name ?? "pages",
    path: indexwdyh8FzEmrMeta?.path ?? "",
    meta: indexwdyh8FzEmrMeta || {},
    alias: indexwdyh8FzEmrMeta?.alias || [],
    redirect: indexwdyh8FzEmrMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pages/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: publicTcFxkFOErFMeta?.name ?? undefined,
    path: publicTcFxkFOErFMeta?.path ?? "/public",
    meta: publicTcFxkFOErFMeta || {},
    alias: publicTcFxkFOErFMeta?.alias || [],
    redirect: publicTcFxkFOErFMeta?.redirect,
    component: () => import("/opt/build/repo/pages/public.vue").then(m => m.default || m),
    children: [
  {
    name: _91file_45id_93R5ptNzD0BqMeta?.name ?? "public-folderid-files-fileid",
    path: _91file_45id_93R5ptNzD0BqMeta?.path ?? ":folderid()/files/:fileid()",
    meta: _91file_45id_93R5ptNzD0BqMeta || {},
    alias: _91file_45id_93R5ptNzD0BqMeta?.alias || [],
    redirect: _91file_45id_93R5ptNzD0BqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/public/[folder-id]/files/[file-id].vue").then(m => m.default || m)
  },
  {
    name: indexMxdv8CiS0vMeta?.name ?? "public-folderid-files",
    path: indexMxdv8CiS0vMeta?.path ?? ":folderid()/files",
    meta: indexMxdv8CiS0vMeta || {},
    alias: indexMxdv8CiS0vMeta?.alias || [],
    redirect: indexMxdv8CiS0vMeta?.redirect,
    component: () => import("/opt/build/repo/pages/public/[folder-id]/files/index.vue").then(m => m.default || m)
  },
  {
    name: index8EYB3b4IpIMeta?.name ?? "public-folderid",
    path: index8EYB3b4IpIMeta?.path ?? ":folderid()",
    meta: index8EYB3b4IpIMeta || {},
    alias: index8EYB3b4IpIMeta?.alias || [],
    redirect: index8EYB3b4IpIMeta?.redirect,
    component: () => import("/opt/build/repo/pages/public/[folder-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexzngo4oBjrwMeta?.name ?? "public",
    path: indexzngo4oBjrwMeta?.path ?? "",
    meta: indexzngo4oBjrwMeta || {},
    alias: indexzngo4oBjrwMeta?.alias || [],
    redirect: indexzngo4oBjrwMeta?.redirect,
    component: () => import("/opt/build/repo/pages/public/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: registeryZbpxRGV6SMeta?.name ?? "register",
    path: registeryZbpxRGV6SMeta?.path ?? "/register",
    meta: registeryZbpxRGV6SMeta || {},
    alias: registeryZbpxRGV6SMeta?.alias || [],
    redirect: registeryZbpxRGV6SMeta?.redirect,
    component: () => import("/opt/build/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settings6ZNFu1CSghMeta?.name ?? "settings",
    path: settings6ZNFu1CSghMeta?.path ?? "/settings",
    meta: settings6ZNFu1CSghMeta || {},
    alias: settings6ZNFu1CSghMeta?.alias || [],
    redirect: settings6ZNFu1CSghMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: templatesXQ4q1AVqc3Meta?.name ?? undefined,
    path: templatesXQ4q1AVqc3Meta?.path ?? "/templates",
    meta: templatesXQ4q1AVqc3Meta || {},
    alias: templatesXQ4q1AVqc3Meta?.alias || [],
    redirect: templatesXQ4q1AVqc3Meta?.redirect,
    component: () => import("/opt/build/repo/pages/templates.vue").then(m => m.default || m),
    children: [
  {
    name: _91file_45id_93kQEmaNYllSMeta?.name ?? "templates-folderid-files-fileid",
    path: _91file_45id_93kQEmaNYllSMeta?.path ?? ":folderid()/files/:fileid()",
    meta: _91file_45id_93kQEmaNYllSMeta || {},
    alias: _91file_45id_93kQEmaNYllSMeta?.alias || [],
    redirect: _91file_45id_93kQEmaNYllSMeta?.redirect,
    component: () => import("/opt/build/repo/pages/templates/[folder-id]/files/[file-id].vue").then(m => m.default || m)
  },
  {
    name: indexZTi6ZP1YMsMeta?.name ?? "templates-folderid-files",
    path: indexZTi6ZP1YMsMeta?.path ?? ":folderid()/files",
    meta: indexZTi6ZP1YMsMeta || {},
    alias: indexZTi6ZP1YMsMeta?.alias || [],
    redirect: indexZTi6ZP1YMsMeta?.redirect,
    component: () => import("/opt/build/repo/pages/templates/[folder-id]/files/index.vue").then(m => m.default || m)
  },
  {
    name: indexZuq7mtGKKGMeta?.name ?? "templates-folderid",
    path: indexZuq7mtGKKGMeta?.path ?? ":folderid()",
    meta: indexZuq7mtGKKGMeta || {},
    alias: indexZuq7mtGKKGMeta?.alias || [],
    redirect: indexZuq7mtGKKGMeta?.redirect,
    component: () => import("/opt/build/repo/pages/templates/[folder-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyKTohDGbkZMeta?.name ?? "templates",
    path: indexyKTohDGbkZMeta?.path ?? "",
    meta: indexyKTohDGbkZMeta || {},
    alias: indexyKTohDGbkZMeta?.alias || [],
    redirect: indexyKTohDGbkZMeta?.redirect,
    component: () => import("/opt/build/repo/pages/templates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: unregisterCsdZJBLwBbMeta?.name ?? "unregister",
    path: unregisterCsdZJBLwBbMeta?.path ?? "/unregister",
    meta: unregisterCsdZJBLwBbMeta || {},
    alias: unregisterCsdZJBLwBbMeta?.alias || [],
    redirect: unregisterCsdZJBLwBbMeta?.redirect,
    component: () => import("/opt/build/repo/pages/unregister.vue").then(m => m.default || m)
  }
]