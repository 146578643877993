import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { initializeAuth } from 'firebase/auth';

export default defineNuxtPlugin((nuxtApp) => {
  const app = initializeApp({
    apiKey: 'AIzaSyCWn_A-3XbZTI40rsvjOTuG0I1VKq2O8nI',
    authDomain: 'meditor-one.firebaseapp.com',
    databaseURL: 'https://meditor-one.firebaseio.com',
    projectId: 'meditor-one',
    storageBucket: 'meditor-one.appspot.com',
    messagingSenderId: '821857467011',
    appId: '1:821857467011:web:4ed4b4e4da79169dfd79b6',
  });

  const firestore = getFirestore(app);
  const auth = initializeAuth(app);

  nuxtApp.provide('firestore', firestore);
  nuxtApp.provide('auth', auth);
});
