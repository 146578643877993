import { _useMonacoState } from '@/composables/useMonaco';
import { defineNuxtPlugin } from '#imports';
import EditorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';

export default defineNuxtPlugin(async () => {
  self.MonacoEnvironment = {
    getWorker: function (_workerId, label) {
      return new EditorWorker({
        name: label,
      });
    },
  };

  const monacoState = _useMonacoState();
  const monaco = await import('monaco-editor/esm/vs/editor/editor.api');

  // anchorSelect
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/anchorSelect/browser/anchorSelect.js'
  // );
  // bracketMatching
  await import(
    'monaco-editor/esm/vs/editor/contrib/bracketMatching/browser/bracketMatching.js'
  );
  // browser
  await import('monaco-editor/esm/vs/editor/browser/coreCommands.js');
  // caretOperations
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/caretOperations/browser/caretOperations.js'
  // );
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/caretOperations/browser/transpose.js'
  // );
  // clipboard
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/clipboard/browser/clipboard.js'
  // );
  // codeAction
  await import(
    'monaco-editor/esm/vs/editor/contrib/codeAction/browser/codeActionContributions.js'
  );
  // codelens
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/codelens/browser/codelensController.js'
  // );
  // colorPicker
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/colorPicker/browser/colorContributions.js'
  // );
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/colorPicker/browser/standaloneColorPickerActions.js'
  // );
  // comment
  await import(
    'monaco-editor/esm/vs/editor/contrib/comment/browser/comment.js'
  );
  // contextmenu
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/contextmenu/browser/contextmenu.js'
  // );
  // cursorUndo
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/cursorUndo/browser/cursorUndo.js'
  // );
  // diffEditor
  // await import(
  //   'monaco-editor/esm/vs/editor/browser/widget/diffEditor/diffEditor.contribution.js'
  // );
  // dnd
  // await import('monaco-editor/esm/vs/editor/contrib/dnd/browser/dnd.js');
  // documentSymbols
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/documentSymbols/browser/documentSymbols.js'
  // );
  // dropOrPasteInto
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/dropOrPasteInto/browser/copyPasteContribution.js'
  // );
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/dropOrPasteInto/browser/dropIntoEditorContribution.js'
  // );
  // find
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/find/browser/findController.js'
  // );
  // folding
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/folding/browser/folding.js'
  // );
  // fontZoom
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/fontZoom/browser/fontZoom.js'
  // );
  // format
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/format/browser/formatActions.js'
  // );
  // gotoError
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/gotoError/browser/gotoError.js'
  // );
  // gotoLine
  // await import(
  //   'monaco-editor/esm/vs/editor/standalone/browser/quickAccess/standaloneGotoLineQuickAccess.js'
  // );
  // gotoSymbol
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/gotoSymbol/browser/goToCommands.js'
  // );
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/gotoSymbol/browser/link/goToDefinitionAtPosition.js'
  // );
  // hover
  await import('monaco-editor/esm/vs/editor/contrib/hover/browser/hover.js');
  // iPadShowKeyboard
  // await import(
  //   'monaco-editor/esm/vs/editor/standalone/browser/iPadShowKeyboard/iPadShowKeyboard.js'
  // );
  // inPlaceReplace
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/inPlaceReplace/browser/inPlaceReplace.js'
  // );
  // indentation
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/indentation/browser/indentation.js'
  // );
  // inlayHints
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/inlayHints/browser/inlayHintsContribution.js'
  // );
  // inlineCompletions
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/inlineCompletions/browser/inlineCompletions.contribution.js'
  // );
  // inlineProgress
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/inlineProgress/browser/inlineProgress.js'
  // );
  // inspectTokens
  await import(
    'monaco-editor/esm/vs/editor/standalone/browser/inspectTokens/inspectTokens.js'
  );
  // lineSelection
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/lineSelection/browser/lineSelection.js'
  // );
  // linesOperations
  await import(
    'monaco-editor/esm/vs/editor/contrib/linesOperations/browser/linesOperations.js'
  );
  // linkedEditing
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/linkedEditing/browser/linkedEditing.js'
  // );
  // links
  await import('monaco-editor/esm/vs/editor/contrib/links/browser/links.js');
  // longLinesHelper
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/longLinesHelper/browser/longLinesHelper.js'
  // );
  // multicursor
  await import(
    'monaco-editor/esm/vs/editor/contrib/multicursor/browser/multicursor.js'
  );
  // parameterHints
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/parameterHints/browser/parameterHints.js'
  // );
  // quickCommand
  await import(
    'monaco-editor/esm/vs/editor/standalone/browser/quickAccess/standaloneCommandsQuickAccess.js'
  );
  // quickHelp
  // await import(
  //   'monaco-editor/esm/vs/editor/standalone/browser/quickAccess/standaloneHelpQuickAccess.js'
  // );
  // quickOutline
  // await import(
  //   'monaco-editor/esm/vs/editor/standalone/browser/quickAccess/standaloneGotoSymbolQuickAccess.js'
  // );
  // readOnlyMessage
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/readOnlyMessage/browser/contribution.js'
  // );
  // referenceSearch
  // await import(
  //   'monaco-editor/esm/vs/editor/standalone/browser/referenceSearch/standaloneReferenceSearch.js'
  // );
  // rename
  // await import('monaco-editor/esm/vs/editor/contrib/rename/browser/rename.js');
  // semanticTokens
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/semanticTokens/browser/documentSemanticTokens.js'
  // );
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/semanticTokens/browser/viewportSemanticTokens.js'
  // );
  // smartSelect
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/smartSelect/browser/smartSelect.js'
  // );
  // snippet
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/snippet/browser/snippetController2.js'
  // );
  // stickyScroll
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/stickyScroll/browser/stickyScrollContribution.js'
  // );
  // suggest
  await import(
    'monaco-editor/esm/vs/editor/contrib/suggest/browser/suggestController.js'
  );
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/suggest/browser/suggestInlineCompletions.js'
  // );
  // toggleHighContrast
  // await import(
  //   'monaco-editor/esm/vs/editor/standalone/browser/toggleHighContrast/toggleHighContrast.js'
  // );
  // toggleTabFocusMode
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/toggleTabFocusMode/browser/toggleTabFocusMode.js'
  // );
  // tokenization
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/tokenization/browser/tokenization.js'
  // );
  // unicodeHighlighter
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/unicodeHighlighter/browser/unicodeHighlighter.js'
  // );
  // unusualLineTerminators
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/unusualLineTerminators/browser/unusualLineTerminators.js'
  // );
  // wordHighlighter
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/wordHighlighter/browser/wordHighlighter.js'
  // );
  // wordOperations
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/wordOperations/browser/wordOperations.js'
  // );
  // wordPartOperations
  // await import(
  //   'monaco-editor/esm/vs/editor/contrib/wordPartOperations/browser/wordPartOperations.js'
  // );

  monacoState.value = monaco;
  // nuxtApp.provide('monaco', monaco);
});
