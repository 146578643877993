import cleanStack from 'clean-stack';
import * as env from 'browser-or-node';
import chalk from 'chalk';

export type LogStates = 'debug' | 'error' | 'info' | 'trace' | 'warn';
export type LogLevel = LogStates | 'silent';

export interface Logger {
  readonly level: LogLevel;
  trace(id: string, fun: string, ...trace: unknown[]): void;
  debug(id: string, fun: string, ...debug: unknown[]): void;
  info(id: string, fun: string, ...info: unknown[]): void;
  warn(id: string, fun: string, ...warn: unknown[]): void;
  error(id: string, fun: string, ...error: unknown[]): void;
}

const LEVEL: { [key: string]: number } = {
  trace: 5,
  debug: 4,
  info: 3,
  warn: 2,
  error: 1,
  silent: 0,
};

let instance: Logger | null = null;

function log(type: LogStates, file: string, fun: string, output: unknown[]) {
  if (env.isBrowser) {
    // browsor colors
    console[type](
      `%cM1 %c${file} %c${fun}`,
      'color: red; font-weight: 900',
      'color: cyan; font-style: italic',
      'color: green',
      '\n',
      ...output
    );
  } else if (env.isNode) {
    // terminal colors
    console[type](
      chalk.bold.red('M1'),
      chalk.italic.cyan(file),
      chalk.green(fun),
      '\n',
      ...output
    );
  } else {
    console[type](`M1 ${file} ${fun}`, ...output);
  }
}

function createLog(
  type: LogStates,
  file: string,
  fun: string,
  output: unknown[]
): void {
  const serialized = output.map((out: unknown) => out);

  // Filter logs
  const config = useRuntimeConfig();

  if (
    config.public.logFilter &&
    (file.match(config.public.logFilter) || fun.match(config.public.logFilter))
  ) {
    log(type, file, fun, serialized);
  }

  // If no filter is passed show all logs
  if (!config.public.logFilter) {
    log(type, file, fun, serialized);
  }
}

/**
 * Exported for testing purposes.
 *
 * @param loglevel The categories of logs allowed to show up.
 * @returns A `Logger` instance.
 */
export function createLogger(loglevel: LogLevel): Logger {
  if (typeof loglevel !== 'string' || typeof LEVEL[loglevel] !== 'number') {
    throw new Error(
      `Expected log level to be any of silent, error, warn, info, debug or trace, was ${loglevel}`,
      {
        cause: 'logger.createLogger()',
      }
    );
  }

  return {
    get level(): LogLevel {
      return loglevel;
    },

    /**
     * Logs any message with a stack trace to stdout.
     *
     * @param trace An array of trace messages.
     */
    trace(id: string, fun: string, ...trace: unknown[]): void {
      if (LEVEL[loglevel] === LEVEL.trace) {
        const fullStack = (new Error().stack || '').split('\n');
        const stack = fullStack.slice(2).join('\n');

        createLog('trace', id, fun, [
          ...trace,
          `\n\n${cleanStack(stack, { pretty: true })}`,
        ]);
      }
    },

    /**
     * Logs any messages with a log level of 'debug' or lower.
     *
     * @param debug An array of debug messages.
     */
    debug(id: string, fun: string, ...debug: unknown[]): void {
      if (LEVEL[loglevel] >= LEVEL.debug) {
        createLog('debug', id, fun, [...debug]);
      }
    },

    /**
     * Logs any messages with log level of 'info' or lower.
     *
     * @param info An array of info messages.
     */
    info(id: string, fun: string, ...info: unknown[]): void {
      if (LEVEL[loglevel] >= LEVEL.info) {
        createLog('info', id, fun, [...info]);
      }
    },

    /**
     * Logs any messages with log level of 'warn' or lower.
     *
     * @param warn An array of warnings.
     */
    warn(id: string, fun: string, ...warn: unknown[]): void {
      if (LEVEL[loglevel] >= LEVEL.warn) {
        createLog('warn', id, fun, [...warn]);
      }
    },

    /**
     * Logs any messages with log level of 'error' or lower.
     *
     * @param error An array of Errors or error messages.
     */
    error(id: string, fun: string, ...error: unknown[]): void {
      if (LEVEL[loglevel] >= LEVEL.error) {
        createLog('error', id, fun, [...error]);
      }
    },
  };
}

/**
 * A simple logger, with an options to store error logs to Sentry.
 *
 * @returns A `Logger` instance.
 */
export default function logger(): Logger {
  if (instance != null) return instance;

  // Store instance
  const config = useRuntimeConfig();
  // Prevent logs from showing up during tests
  const logLevel =
    process.env.VITEST === 'true' ? 'error' : config.public.logLevel;
  instance = createLogger(logLevel as LogLevel);

  return instance;
}
