<script setup lang="ts">
import type { NuxtError } from '#app';
import { useTheme } from 'vuetify';

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: Object as () => NuxtError,
});

const theme = useTheme();

const themeClass = computed(() => {
  // v-theme--{light,dark}
  return `v-theme--${theme.global.name.value}`;
});

onMounted(() => {
  const { $sentry } = useNuxtApp();
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    theme.global.name.value = 'dark';
  } else {
    theme.global.name.value = 'light';
  }

  $sentry.captureException(props.error);
});

function handleError() {
  clearError({ redirect: '/' });
}
</script>

<template>
  <v-layout>
    <div
      class="base-layout bg-surface d-flex flex-column justify-center align-center"
      :class="{
        [themeClass]: true,
      }"
    >
      <h1 class="text-h1 text-error">{{ props.error?.statusCode ?? 500 }}</h1>
      <sub class="text-h2">{{ props.error?.message }}</sub>
      <!-- FIXME: xss attacks? -->
      <!-- eslint-disable-next-line vue/no-v-html -->
      <code
        v-if="props.error?.stack"
        v-html="props.error.stack"
      />
      <v-btn
        variant="outlined"
        size="x-large"
        @click="handleError"
      >
        HOME
      </v-btn>
    </div>
  </v-layout>
</template>

<style scoped lang="scss">
code {
  padding: 2rem;
}

sub {
  bottom: 0;
}
</style>
