import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const {
    public: { sentry },
  } = useRuntimeConfig();
  const router = useRouter();

  Sentry.init({
    app: [vueApp],
    dsn: sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    environment: process.dev ? 'development' : 'production',

    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line no-console
        logger().error(
          `sentry.client.ts`,
          'beforeSend()',
          hint.originalException,
          {
            event,
            hint,
          }
        );
      } else {
        logger().info('plugins/sentry.client.ts', 'beforeSend', event, hint);
      }
      // Continue sending to Sentry
      return event;
    },
  });

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'create', 'destroy', 'mount', 'update', 'unmount'],
    })
  );

  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update', 'unmount'],
  });

  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    Sentry.withScope((scope) => {
      scope.setExtra('context', context);
      Sentry.captureException(error);
    });
  };

  nuxtApp.vueApp.config.warnHandler = (message, context) => {
    logger().warn('sentry.client.ts', 'warnHandler()', message, context);
  };

  nuxtApp.hook('app:error', (error) => {
    logger().error('sentry.client.ts', 'app:error', error);
    Sentry.captureException(error);
  });

  nuxtApp.provide('sentry', Sentry);
});
