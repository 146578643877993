import type * as monaco from 'monaco-editor';
import { useState } from '#imports';

export const _useMonacoState = () => {
  return useState<typeof monaco | null>('MonacoEditorNamespace', () => null);
};

/**
 * Get `monaco` namespace
 * @returns `monaco` namespace: if unavailable (server-side), returns `null`
 */
export const useMonaco = (): typeof monaco | null => _useMonacoState().value;
