// import "vuetify/styles";
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import {
  mdiBookOpenOutline,
  mdiBookmarkPlus,
  mdiBookmarkPlusOutline,
  mdiBugOutline,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiClipboardCheckOutline,
  mdiClipboardTextOutline,
  mdiClockEditOutline,
  mdiCodeBraces,
  mdiCogOutline,
  mdiContentCopy,
  mdiDatabaseAlertOutline,
  mdiDatabaseOutline,
  mdiDelete,
  mdiDeleteOutline,
  mdiFileCodeOutline,
  mdiFileDocumentPlus,
  mdiFileDocumentPlusOutline,
  mdiFolderMultipleOutline,
  mdiFolderPlus,
  mdiFolderPlusOutline,
  mdiFolderStarMultipleOutline,
  mdiLogoutVariant,
  mdiPencil,
  mdiPencilOutline,
  mdiSecurity,
  mdiSelection,
  mdiShieldAlertOutline,
  mdiShieldLockOutline,
  mdiSwapVertical,
} from '@mdi/js';
import light from '@primer/primitives/dist/json/colors/light.json';
import dark from '@primer/primitives/dist/json/colors/dark.json';

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: true,
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
      aliases: {
        ...aliases,
        add: mdiFileDocumentPlus,
        addFolder: mdiFolderPlus,
        addFolderOpen: mdiFolderPlusOutline,
        addOpen: mdiFileDocumentPlusOutline,
        admin: mdiSecurity,
        back: mdiChevronLeft,
        bookmark: mdiBookmarkPlus,
        bookmarkOpen: mdiBookmarkPlusOutline,
        bugs: mdiBugOutline,
        changed: mdiClockEditOutline,
        clipboard: mdiClipboardCheckOutline,
        copy: mdiContentCopy,
        dirty: mdiDatabaseAlertOutline,
        docs: mdiBookOpenOutline,
        down: mdiChevronDown,
        edit: mdiPencil,
        editOpen: mdiPencilOutline,
        files: mdiFileCodeOutline,
        forward: mdiChevronRight,
        fresh: mdiDatabaseOutline,
        insecure: mdiShieldAlertOutline,
        logOut: mdiLogoutVariant,
        pages: mdiClipboardTextOutline,
        public: mdiFolderStarMultipleOutline,
        remove: mdiDelete,
        removeOpen: mdiDeleteOutline,
        secure: mdiShieldLockOutline,
        select: mdiSelection,
        settings: mdiCogOutline,
        snippet: mdiCodeBraces,
        swap: mdiSwapVertical,
        templates: mdiFolderMultipleOutline,
        up: mdiChevronUp,
      },
      sets: {
        mdi,
      },
    },
    theme: {
      themes: {
        light: {
          colors: {
            background: '#fafafa',
            surface: '#fafafa',
            primary: '#526fff',
            // secondary: 'string',
            // success: 'string',
            // warning: 'string',
            // error: 'string',
            // info: 'string',
            nav: '#fafafa',
            icon: '#9d9d9f',
            'list-panel': '#eaeaeb',
            border: '#dbdbdc',
            success: light.success.fg,
            error: light.danger.fg,
            info: '#526fff',
            warn: light.severe.fg,
          },
        },
        dark: {
          colors: {
            background: '#282c34',
            surface: '#1e1e1e',
            primary: '#528bff',
            // secondary: 'string',
            // success: 'string',
            // warning: 'string',
            // error: 'string',
            // info: 'string',
            nav: '#333842',
            icon: '#333842',
            'list-panel': '#21252b',
            border: '#181a1f',
            success: dark.success.fg,
            error: dark.danger.fg,
            info: '#528bff',
            warn: dark.severe.fg,
          },
        },
      },
    },
  });

  nuxtApp.vueApp.use(vuetify);
});
