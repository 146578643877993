export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, height=device-height, initial-scale=1, interactive-widget=resizes-content"},{"charset":"utf-8"},{"name":"theme-color","content":"#22c55e"}],"link":[{"rel":"icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"},{"rel":"mask-icon","href":"/icon.svg","color":"#FFFFFF"}],"style":[],"script":[],"noscript":[],"title":"Meditor.one","charset":"utf-8","viewport":"width=device-width, height=device-height, initial-scale=1, interactive-widget=resizes-content"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false